//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as moment from 'moment-timezone';

export default {
  name: 'DateComponent',
  props: ['event', 'uploadedImg', 'attendanceOptions', 'recurrentString'],
  components: {},
  data() {
    return {
      recurrentOptions: [
        { name: this.$i18n.t('events.create.recurrency_daily'), code: 'DAILY' },
        {
          name: this.$i18n.t('events.create.recurrency_weekly'),
          code: 'WEEKLY',
        },
        {
          name: this.$i18n.t('events.create.recurrency_workdays'),
          code: 'WORKDAYS',
        },
      ],
    };
  },
  methods: {},
  computed: {
    recurrence() {
      return this.recurrentOptions.filter(
        (it) => it.code === this.event.recurrenceFrequency
      )[0].name;
    },
    dateFrom() {
      return moment(this.event.dateFrom, 'YYYY/MM/DD')
        .tz(this.event.timezone)
        .setTime(this.event.timeFrom)
        .format();
    },
    dateTo() {
      return moment(this.event.dateTo, 'YYYY/MM/DD')
        .tz(this.event.timezone)
        .setTime(this.event.timeTo)
        .format();
    },
    recurrenceTo() {
      return moment(this.event.recurrenceTo, 'YYYY/MM/DD')
        .tz(this.event.timezone)
        .setTime(this.event.timeTo)
        .format();
    },
    sameDate() {
      return this.event.dateFrom == this.event.dateTo;
    },
    attendanceSelected() {
      return this.attendanceOptions.filter(
        (aop) => aop.id == this.event.attendanceType
      )[0].type;
    },
    timeFrom() {
      if (!this.dateFrom || !this.event.timezone) return null;
      return new Date(this.dateFrom).toLocaleString(this.$i18n.locale, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.event.timezone,
      });
    },
    timeTo() {
      if (!this.dateTo || !this.event.timezone) return null;
      return new Date(this.dateTo).toLocaleString(this.$i18n.locale, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.event.timezone,
      });
    },
    languageTags() {
      if (!this.event.tags) return [];
      return this.event.tags.filter(
        (i) => i.category.toLowerCase() === 'language'
      );
    },
    teachingTags() {
      if (!this.event.tags) return [];
      return this.event.tags.filter(
        (i) => i.category.toLowerCase() === 'teaching'
      );
    },
  },
  watch: {},
  mounted() {},
  created() {},
};
