import { render, staticRenderFns } from "./EventCreate.vue?vue&type=template&id=b64fafc6&"
import script from "./EventCreate.vue?vue&type=script&lang=js&"
export * from "./EventCreate.vue?vue&type=script&lang=js&"
import style0 from "./EventCreate.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QItem,QBtn,QStepper,QStep,QForm,QInput,QIcon,QSelect,QItemSection,QToggle,QItemLabel,QDialog,Intersection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QItem,QBtn,QStepper,QStep,QForm,QInput,QIcon,QSelect,QItemSection,QToggle,QItemLabel,QDialog})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Intersection})
