//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import "context-filter-polyfill";
import CropImage from '@/components/EventCreate/CropImage.vue';
import DateComponent from '@/components/EventCreate/Date.vue';
import DuplicateEventModal from '@/components/EventCreate/DuplicateEventModal.vue';
import SummaryComponent from '@/components/EventCreate/Summary.vue';
import TagComponent from '@/components/EventCreate/Tag.vue';
import UnsavedChangesModal from '@/components/EventCreate/UnsavedChangesModal.vue';
import VenueComponent from '@/components/EventCreate/Venue.vue';
import { Events, AttendanceTypes } from '@/services/api.service';
import { EventBus } from '@/services/event-bus';
import { TimeHelper } from '@/services/helpers';
import { EventModel } from '@/services/model';
import * as moment from 'moment-timezone';
import { scroller } from 'vue-scrollto/src/scrollTo';

const momentPrototype = Object.getPrototypeOf(moment());
const ScrollTo = scroller();

/**
 * Set time helper, accepts time string like HH:mm
 */
momentPrototype.setTime = function (time) {
  if (!time) return this;
  const [hour, minute] = time.split(':');
  return this.set({ hour, minute });
};

const thresholds = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i);
}

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export default {
  name: 'EventCreate',
  data() {
    return {
      errors: {},
      formStep: 1,
      event: new EventModel(), // EventModel

      dateValidation: {}, // object with date related validation (for single event so far)
      recurrentValidations: [], // array of recurrent event validation errors
      recurrentString: '', // string explaining recurrent event
      recurrentEventsToBeCreated: {}, // object from API telling user which events will be created

      active_tab: 'title',
      attendanceOptions: [],
      eventSaved: false,
      isEditing: false,
      inProgress: false,

      showDuplicateEventModal: false,
      showUnsavedChangesModal: false,
      nextRoute: null,

      showPublicEventModal: false,
      duplicateEventData: null,
      noCheckPrivacy: false,
      intersectionOptions: {
        handler: this.onIntersection,
        cfg: {
          threshold: thresholds,
        },
      },
      tmpImg: null,
      imageInfoModal: false,
      venue: {
        title: null,
      },
      loadingCounter: 0,
    };
  },
  methods: {
    setVenue(venue) {
      this.event.venue = venue;
      this.event.timezone = venue.timezone || 'UTC';
      this.setTimezone();
      if (!venue || !venue.id) return;
      this.venue_search_in_progress = true;
    },
    setDescription(val) {
      // description model didnt update via v-model
      this.event.description = val;
      this.$forceUpdate();
    },
    onIntersection(obj) {
      const percent = (obj.intersectionRatio * 100).toFixed(0);
      if (percent > 80) {
        this.active_tab = obj.target.id;
      }
    },

    /**
     * Prefills event data (for editation and duplication of events)
     */
    prefillEvent(event) {
      this.preloadedEvent = event;

      this.event.id = event.id;
      this.event.going = event.followed;
      this.event.title = event.title;

      this.event.tags = event.tags;
      this.event.image = event.image;

      if (this.$route.name == 'event-edit') {
        // prefill dates only for editing, not duplicating
        this.setDateFrom({ date: event.date });
        this.setDateTo({ date: event.dateTo });
      }

      this.event.description = event.description;
      this.event.attendanceType = event.attendanceType.id;
      this.event.isRecurrent = false; // No recurrent event editation so far
      this.event.isOfficial = event.official;
      this.event.existingImage = event.image != '';
      this.event.url = event.url;
      this.event.password = event.password;

      // prefill venue in other component
      this.$refs.venueComponent.setVenue(event.venue);
      this.$refs.venueComponent.setVenueType(event.type);

      if (event.recurrentV2Parent) {
        // has parent --> is recurrent -> should show recurrent options
        this.event.editRecurrent = true;
        this.event.isRecurrent = null;
      } else {
        this.event.editRecurrent = false;
      }

      if (
        this.$route.name == 'event-create' ||
        this.$route.name == 'event-edit'
      ) {
        this.event.timeFrom = TimeHelper.getTimeString(
          event.date,
          null,
          this.event.timezone
        );
        this.event.timeTo = TimeHelper.getTimeString(
          event.dateTo,
          null,
          this.event.timezone
        );
      }
    },

    checkDuplicateEvent() {
      this.errors = {};
      this.validateSubmitForm(true); // only first step

      if (Object.keys(this.errors).length) {
        let alreadyScrolled = false;
        if (this.errors.venueMissing) {
          ScrollTo('#location', 500, { offset: -150 });
          alreadyScrolled = true;
        }
        if (this.errors.datesInvalid) {
          if (!alreadyScrolled) {
            ScrollTo('#date', 500, { offset: -150 });
            alreadyScrolled = true;
          }
        }
        return;
      }

      let duplicateCheckDate = this.event.dateFrom;
      
      // on recurrent event dont use dateFrom, instead use 1st computed date from API 
      if (this.event.isRecurrent && !this.isEditing && this.recurrentEventsToBeCreated && this.recurrentEventsToBeCreated.count) {
        duplicateCheckDate = this.recurrentEventsToBeCreated.dates[0];
        // console.log(duplicateCheckDate, "is check date");
      }

      let dataObj = {
        date: moment(duplicateCheckDate, 'YYYY/MM/DD')
          .tz(this.event.timezone)
          .setTime(this.event.timeFrom)
          .format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`,
        venue_id: this.event.venue.id, // required	ID of venue, where the event takes place
        checkHybrid: this.event.venueType.toUpperCase() === 'HYBRID',
      };

      this.$q.loading.show();

      let duplicateCheckPromise;
      if (this.isEditing) {
        // dont do duplicate check on editing
        window.scrollTo(0, 150);
        duplicateCheckPromise = new Promise((resolve) => {
          this.$q.loading.hide();
          resolve(true);
        });
      } else {
        duplicateCheckPromise = Events.listDuplicateEvents(dataObj).then(
          (duplicateEventsRes) => {
            if (
              duplicateEventsRes.data.data.duplicateEvents &&
              duplicateEventsRes.data.data.duplicateEvents.length > 0
            ) {
              return new Promise((resolve, reject) => {
                let list = duplicateEventsRes.data.data.duplicateEvents || [];
                list.forEach((event) => {
                  event.inProgress = moment().isBetween(
                    event.date,
                    event.dateTo
                  );
                  event.inPast = moment().diff(event.dateTo) > 0;
                  event.inFuture = moment().diff(event.date) < 0;
                });
                this.$q.loading.hide();
                reject(list); // reject = There are some conflicting events
              });
            } else {
              window.scrollTo(0, 150);
              return new Promise((resolve) => {
                this.$q.loading.hide();
                resolve(true); // resolve = There are no conflicting events
              });
            }
          }
        );
      }

      duplicateCheckPromise.then(
        () => {
          this.formStep = 2;
        },
        (err) => {
          this.$q.loading.hide();
          this.showDuplicateEventModal = true;
          this.duplicateEventData = err;
        }
      );
    },

    onFileChanged(file) {
      this.tmpImg = file;
      this.event.imageChanged = true;
    },

    /**
     * form validations
     **/
    validateSubmitForm(onlyFirstStep = false) {
      if (this.event.isRecurrent) {
        // special case for recurrent events -- date to == date from
        this.event.dateTo = this.event.dateFrom;
      }
      this.errors = {};

      if (!this.event.venue || !this.event.venue.id) {
        this.errors.venueMissing = true;
      }
      if (
        this.event.venueType.toUpperCase() === 'ONLINE' &&
        (!this.event.venue || !this.event.venue.specialVenue)
      ) {
        this.errors.venueMissing = true;
      }
      if (
        (this.event.venueType.toUpperCase() === 'ONSITE' ||
          this.event.venueType.toUpperCase() === 'HYBRID') &&
        (!this.event.venue ||
          (this.event.venue && this.event.venue.specialVenue === true))
      ) {
        this.errors.venueMissing = true;
      }
      if (this.event.isRecurrent === null) {
        this.errors.dateTypeMissing = true;
      }
      if (!this.event.venueType) {
        this.errors.venueTypeMissing = true;
      }
      if (this.event.isRecurrent == true) {
        this.recurrentValidations.forEach((i) => {
          this.errors[i] = true;
        });

      } else {

        const validDateFrom =
          this.event.dateFrom != null &&
          moment(this.event.dateFrom, 'YYYY/MM/DD').isValid(); 
        const validDateTo =
          this.event.dateTo != null &&
          this.event.dateFrom &&
          moment(this.event.dateTo, 'YYYY/MM/DD').isValid(); 
        const validTimeFrom =
          this.event.timeFrom != null && this.event.timeFrom != '';
        const validTimeTo = this.event.timeTo != null && this.event.timeTo != '';

        if (validDateFrom && validDateTo && validTimeFrom && validTimeTo) {
          const dtFrom = moment(this.event.dateFrom, 'YYYY/MM/DD')
            .tz(this.event.timezone)
            .setTime(this.event.timeFrom);
          const dtTo = moment(this.event.dateTo, 'YYYY/MM/DD')
            .tz(this.event.timezone)
            .setTime(this.event.timeTo);
          if (
            !dtFrom.isSameOrAfter(moment(), 'day') ||
            !dtTo.isSameOrAfter(moment(), 'day') ||
            !dtTo.isSameOrAfter(dtFrom, 'day')
          ) {
            this.errors.datesInPast = true;
          }
        } else {
          this.errors.datesInvalid = true;
        }
      }

      if (!this.event.timezone) {
        this.errors.timezoneMissing = true;
      }

      if (onlyFirstStep) return Object.keys(this.errors);

      if (!this.event.title) {
        this.errors.titleMissing = true;
      }
      if (!this.event.attendanceType) {
        this.errors.attendanceTypeMissing = true;
      }
      if (
        !this.event.tags ||
        !this.event.tags.filter((i) => i.category.toLowerCase() === 'language')
          .length
      ) {
        this.errors.tagsMissing = true;
      }

      return Object.keys(this.errors);
    },
    onSubmitError() {
      setTimeout(() => {
        this.validateSubmitForm();
        this.$forceUpdate();
        this.$q.notify({
          type: 'warning',
          message: this.$i18n.t('events.create.error.required_fields_missing'),
          caption: this.$i18n.t(
            'events.create.error.required_fields_missing_text'
          ),
        });
      }, 0);
    },

    /**
     * preview event handler
     */
    eventPreviewSubmit() {
      let errArr = this.validateSubmitForm();
      if (!errArr.length) this.formStep = 3;
      else {
        this.onSubmitError();
      }
    },

    createEventSuccess() {
      this.inProgress = false;
      this.$q.loading.hide();
      this.eventSaved = true;
      EventBus.$emit('onListingRequested', {
        fromRecurring: this.event.isRecurrent ? 1 : 0,
      });
    },

    createEventFailed(err) {
      this.inProgress = false;
      this.$q.loading.hide();
      this.eventSaved = false;
      this.errorSavingData = true;
      this.$q.notify({
        type: 'negative',
        message: this.$i18n.t('events.create.error.create_event_failed'),
        caption: err,
      });
    },

    /**
     * Create event submit handler
     */
    eventCreateSubmit($e) {
      $e && $e.preventDefault();
      this.errors = {};

      if (Object.keys(this.errors).length) {
        return this.onSubmitError();
      }

      let dataObj = {
        title: this.event.title, // required	Title of the event
        description: this.event.description ? this.event.description : '', //	Description of the event
        date: moment(this.event.dateFrom, 'YYYY/MM/DD')
          .tz(this.event.timezone)
          .setTime(this.event.timeFrom)
          .format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
        date_to: moment(this.event.dateTo, 'YYYY/MM/DD')
          .tz(this.event.timezone)
          .setTime(this.event.timeTo)
          .format(), //	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
        venue_id: this.event.venue.id, // required	ID of venue, where the event takes place
        attendance_type_id: this.event.attendanceType, // id of Event Attendance Type,
        official: this.event.isOfficial,
        timezone: this.event.timezone,
        url: this.event.url,
        password: this.event.password,
        tags: this.event.tags.map((i) => i.code),
        exclude_automatic_follow: !this.event.going,
        type: this.event.venueType.toUpperCase(),
      };

      if (this.event.isRecurrent) {
        const recurrentOptions = {
          date: moment(this.event.firstDate, 'YYYY/MM/DD')
            .tz(this.event.timezone)
            .setTime(this.event.timeFrom)
            .format(), //, this.event.timezone).format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
          date_to: moment(this.event.firstDate, 'YYYY/MM/DD')
            .tz(this.event.timezone)
            .setTime(this.event.timeTo)
            .format(), //, this.event.timezone).format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
          last_possible_date: moment(
            this.event.recurrenceTo,
            'YYYY/MM/DD'
          ).format(),
          recurrent_type: this.event.recurrentType,
          recurrent_preset: this.event.recurrentPreset,
          recurrent_every:
            this.event.recurrentType !== 'MONTHLY'
              ? this.event.recurrentFrequency
              : 1,
          recurrent_daily_weekends: this.event.recurrentDailyDetails,
          recurrent_monthly_by: this.event.recurrentMonthlyType,
          recurrent_weekly_days: this.event.recurrentWeeklyDetails,
          recurrent_monthly_by_date: this.event.recurrentMonthlyDates.sort(
            (a, b) => a - b
          ),
          recurrent_monthly_by_each: this.event.recurrentMonthlyEach.sort(
            (a, b) => a - b
          ),
        };

        dataObj = { ...dataObj, ...recurrentOptions };
      }

      this.errorSavingData = false;
      this.inProgress = true;
      this.$q.loading.show();

      if (this.event.imageChanged && this.event.image) {
        dataObj.image = this.event.image;
      }

      if (!this.isEditing) {
        // CREATING NEW
        let action;
        if (this.event.isRecurrent) action = Events.addRecurrent(dataObj);
        if (!this.event.isRecurrent) action = Events.add(dataObj);
        action.then(this.createEventSuccess, this.createEventFailed);
      } else {
        // EDITING
        if (this.event.going != this.preloadedEvent.followed) {
          // follow or unfollow event
          let followPromise = this.event.going
            ? Events.follow(this.preloadedEvent.id)
            : Events.unfollow(this.preloadedEvent.id);
          followPromise.then(
            () => {
              console.log('follow set');
            },
            () => {
              console.log('follow NOT set');
            }
          );
        }

        if (this.event.isRecurrent) {
          const myId = this.preloadedEvent.id;
          const parentId = this.preloadedEvent.recurrentV2Parent.id;
          if (!myId || !parentId) throw new Error('No recurrent parent or id');

          // on recurrent event: update it, check if returned event is not null, if it is, show dialog and set forceEdit=true
          Events.updateRecurrent(myId, parentId, dataObj, false).then(
            (data) => {
              if (data.event === null) {
                this.inProgress = false;
                this.$q.loading.hide();
                this.$q
                  .dialog({
                    title: this.$i18n.t(
                      'events.create.edit_recurrence_dialog.title'
                    ),
                    message: this.$i18n.t(
                      'events.create.edit_recurrence_dialog.subtitle'
                    ),
                    cancel: {
                      flat: true,
                      label: this.$i18n.t('common.cancel'),
                    },
                    persistent: true,
                  })
                  .onOk(() => {
                    this.inProgress = true;
                    this.$q.loading.show();
                    Events.updateRecurrent(
                      myId,
                      parentId,
                      dataObj,
                      true /* !! force edit !! */
                    ).then(this.createEventSuccess, this.createEventFailed);
                  });
              } else {
                this.createEventSuccess();
              }
            },
            this.createEventFailed
          );
        } else {
          // non-recurrent event
          Events.update(this.preloadedEvent.id, dataObj).then(
            this.createEventSuccess,
            this.createEventFailed
          );
        }
      }
    },

    gotoEvents($e) {
      if ($e) $e.preventDefault();
      EventBus.$emit('onListingRequested');
    },
    setDateFrom(par) {
      this.event.dateFrom = moment(par.date).format();
    },
    setDateTo(par) {
      this.event.dateTo = moment(par.date).format();
    },
    setTimezone() {
      // no null timezone, only UTC
      if (this.event.timezone === null) {
        this.event.timezone = 'UTC';
      }
      moment.tz.setDefault(this.event.timezone);
    },

    /**
     * fills attendance types (free, sms, transmission,...)
     */
    async fillAttendanceTypes() {
      this.loadingCounter++;
      let types = await AttendanceTypes.list();
      this.loadingCounter--;
      if (
        types.data.data.attendanceTypes &&
        types.data.data.attendanceTypes.length
      ) {
        this.attendanceOptions = types.data.data.attendanceTypes;
        return types.data.data.attendanceTypes.length;
      }
      return false;
    },

    onDuplicateContinue() {
      setTimeout(() => {
        this.formStep = 2;
      }, 100);
    },
    ignore($e) {
      if ($e.target.nodeName != 'TEXTAREA') {
        $e.preventDefault();
        $e.stopPropagation();
      }
      return null;
    },
  },
  watch: {
    loadingCounter(val) {
      if (val > 0) this.$q.loading.show();
      else this.$q.loading.hide();
    },
    formStep() {
      window.scrollTo(0, 150);
      setTimeout(() => {
        window.scrollTo(0, 150);
      }, 450);
    },
  },
  mounted() {
    this.inProgress = false;
    this.fillAttendanceTypes();

    if (this.$route.name == 'event-edit' && this.$route.params.id) {
      // preload for editing and other edit-* stuff
      this.isEditing = true;
      this.inProgress = true;
      this.loadingCounter++;
      Events.getSingle(this.$route.params.id).then(
        (response) => {
          this.inProgress = false;
          this.prefillEvent(response.data.data.getEvent || {});
          this.loadingCounter--;
        },
        () => {
          this.inProgress = false;
          this.loadingCounter--;
        }
      );
    } else {
      if (this.$route.params.id) {
        // preload existing id
        this.inProgress = true;
        this.loadingCounter++;
        Events.getSingle(this.$route.params.id).then(
          (response) => {
            if (
              !response ||
              !response.data ||
              !response.data.data ||
              !response.data.data.getEvent
            ) {
              this.inProgress = false;
              this.loadingCounter--;
            }

            this.prefillEvent(response.data.data.getEvent || {});
            this.inProgress = false;
            this.loadingCounter--;
          },
          () => {
            this.inProgress = false;
            this.loadingCounter--;
          }
        );
      }
    }
  },
  computed: {},
  created() {},
  components: {
    VenueComponent,
    DateComponent,
    TagComponent,
    DuplicateEventModal,
    UnsavedChangesModal,
    SummaryComponent,
    CropImage,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.eventSaved && next) {
      this.showUnsavedChangesModal = true;
      this.nextRoute = next;
      next(false);
    } else {
      next();
    }
  },
};
