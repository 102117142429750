import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=1b2e4cb0&scoped=true&"
import script from "./Summary.vue?vue&type=script&lang=js&"
export * from "./Summary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2e4cb0",
  null
  
)

export default component.exports
import {QBadge,QIcon,QCard,QImg,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBadge,QIcon,QCard,QImg,QCardSection})
